



















































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { appName, apiUrl } from '@/env';
import { readLoginError } from '@/store/main/getters';
import { dispatchLogIn, dispatchSSOLogIn } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public appName = appName;
  public apiUrl = apiUrl;

  public get loginError() {
    return readLoginError(this.$store);
  }

  public submit() {
    dispatchLogIn(this.$store, {username: this.email, password: this.password});
  }

  public async loginSSO() {
    const response = await api.loginMsal();
    console.log(response);
    if (response !== undefined) {
      dispatchSSOLogIn(this.$store, {idToken: response.idToken, authToken: response.accessToken});
    }
  }
}
